/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Images
import PythonLogo from "assets/images/pythonlogo.png";
import SQLogo from "assets/images/sqlserver-logo.png";
import ReactLogo from "assets/images/react-logo.png";
import SnowLogo from "assets/images/snowflakelogo.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";


// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-data-analytics.jpeg";

function ERP() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Data Analytics and Reporting  
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="warning"
              title="Business Problem"
              description="Our client aggregates data from multiple industry sources to create a single source of truth for reporting and analytics back to providers. 
                           They used Microsoft Access to manage the data; one file per provider (20), per month for 20 years.  
                           The data was normalized manually by one person and then combined into a single file for publication.
                           Multiple attempts had been made to modernize the proces; all had failed."
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="lightbulb"
              title="Vero Software Solution"
              description="It took time to build trust.  We worked with the client to fully understand and document the user stories and backlogs. 
              We organized the backlog into sprints and worked with the client to prioritize the work. 
              The solution used a combination of Microsoft SQL Server, Python, and React that was scalable and maintainable.  
              We used Snowflake to host the data and and Azure for the Web Application to provide a secure and scalable platform for the client."
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="favorites"
              title="Business Results"
              description="The total processing time was reduced by 30%.  
              The data was consolidated into a single, centralized database.  
              We leverage machine learning to automate the data normalization process. 
              They now have the ability to share data across the organization.
              Data publication has been fully automated to the web application.  
              The client now has the ability to provide data to providers in a secure and scalable manner."
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
          <Grid container spacing={12} justifyContent="center" >
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={ReactLogo}
                alt="Microsoft"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={SQLogo}
                alt="Epicor"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={PythonLogo}
                alt="SysPro"
                width="100%"/>
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={SnowLogo}
                alt="SysPro"
                width="100%"/>
            </Grid>
          </Grid>
      </Card>
      <VeroFooter />
    </>
  );
}

export default ERP;
