/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Images
import MsftLogo from "assets/images/microsoftlogo.png";
import SentinelLogo from "assets/images/sentinalonelogo.png";
import KaseyaLogo from "assets/images/kaseyalogo.png";
import AcronisLogo from "assets/images/acronis.png";


// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Divider from "@mui/material/Divider";

// import AppDevServices from "pages/Presentation/sections/AppDevServices";
// import Environments from "pages/Presentation/sections/Environments";
// import Agile from "pages/Presentation/sections/Agile";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-coworking.jpeg";

function NetworkSupport() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Network Support
              </MKTypography>
              <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                Vero is a Microsoft Certified Partner.  Can manage your network and provide support for your users.  
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="cloud"
              title="Migration to Cloud"
              description="" />
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
              We have a structured process for moving data, applications, and other business elements from an on-premises environment to a cloud computing environment. 
This transition offers benefits, including scalability, flexibility, and cost-efficiency. 
Here are key steps for a successful cloud migration:
<br/>
<br/>
<il>
<li>
<strong>Assessment and Planning: </strong>Evaluate your current infrastructure, identify the components to be migrated, and assess the compatibility with cloud. 
Create a detailed migration plan, considering factors like data sensitivity, dependencies, and performance requirements.  We then create the cloud environment
and landing zones for each application type.</li>
<li>
<strong>Data Migration: </strong>Transfer your data to the cloud. This involves moving databases, files, and other critical information. 
Depending on the volume, you might use different strategies like bulk transfer, incremental updates, or streaming data.</li>
<li>
<strong>Application Migration: </strong>Move your applications to the cloud, ensuring they are compatible with the cloud environment. </li>
<li>
<strong>Testing: </strong>Thoroughly test your migrated applications and data to ensure they function correctly in the cloud environment. 
This includes performance testing, security testing, and validating that all dependencies are appropriately configured.</li>
<li>
<strong>Optimization and Monitoring: </strong>Continuously optimize your cloud resources to maximize efficiency and cost-effectiveness. 
Implement robust monitoring tools to keep track of performance, security, and costs. Regularly review and adjust your cloud architecture as needed.</li>
</il>
<br/>
These steps provide a general framework, and the specifics can vary based on the nature of your IT infrastructure and the chosen cloud platform.
            </MKTypography>
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="supportagent"
              title="Desktop Support"
              description=""
            />
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
            
Our Desktop Support Services are tailored to ensure seamless and efficient IT operations. We provide comprehensive technical assistance to end-users, addressing a wide range of hardware and software-related issues.
<br/>
<br/>
Key Features:
<br/>
<br/>
<il>
<li>
<b>Hardware Support:</b> Swift resolution of hardware, software and peripheral connectivity problems to minimize downtime and keep your systems running smoothly.
</li>
<li>
<b>Software Assistance:</b> Expert support for software applications, including installation, configuration, and troubleshooting. We ensure that your software environment is optimized for performance and reliability.
</li>
<li>
<b>Troubleshooting and Issue Resolution:</b> Rapid identification and resolution of technical problems reported by end-users, employing effective diagnostic tools and methodologies to keep your operations uninterrupted.
</li>
<li>
<b>Security Measures:</b> Implementation and enforcement of robust security measures, including regular updates and antivirus/threat management to safeguard your organization&apos;s data and systems.
</li>
<li>
<b>Remote Support:</b> Adapted to support the modern work environment, our services include remote support capabilities, ensuring that your remote workforce receives timely and effective technical assistance.
</li>
</il>
<br/>
With a commitment to excellent customer service, continuous learning, and detailed documentation practices, our Desktop Support Services aim to enhance your overall IT experience, empowering your team to focus on core business activities. Partner with us for reliable, responsive, and proactive desktop support solutions tailored to your organization&apos;s unique needs.
            <br/>
            <br/>
            </MKTypography> 
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="devices"
              title="Network Monitoring"
              description=""
            />
              <MKTypography variant="body2" color="secondary" fontWeight="regular">
            
              Elevate the reliability and performance of your IT infrastructure with our comprehensive Network Monitoring Services. Our expert team ensures the continuous visibility and health of your network, proactively identifying and addressing issues to minimize downtime and enhance overall operational efficiency.
            <br/>
            <br/>
            Key Features:
            <br/>
            <br/>
            <il>
            <li>
            <b>Fault Identification and Resolution:</b> Rapid identification and resolution of network faults, ensuring that connectivity issues, bottlenecks, and potential disruptions are addressed promptly to maintain a seamless flow of data.
            </li>
            <li>
<b>Performance Optimization:</b> Continuous analysis and optimization of network performance to enhance speed, reliability, and efficiency. This includes bandwidth management, traffic analysis, and proactive measures to prevent performance degradation.
            </li>
            <li>
<b>Security Monitoring:</b> Comprehensive security monitoring to detect and respond to potential security threats promptly. This includes intrusion detection, anomaly detection, and regular security assessments to safeguard your network from cyber threats.
            </li>
            </il>
            <br/>
            With a commitment to proactive monitoring, rapid issue resolution, and continuous improvement, our Network Monitoring Services provide the foundation for a robust and secure IT environment. 
                        <br/>
                        <br/>
                        </MKTypography> 
                      </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
          <Grid container spacing={12} justifyContent="center" >
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={MsftLogo}
                alt="Microsoft"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={SentinelLogo}
                alt="SentinelOne"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={KaseyaLogo}
                alt="Kaseya"
                width="100%"              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={AcronisLogo}
                alt="Acronis"
                width="100%"
              />
            </Grid>
          </Grid>    
      </Card>            
      <VeroFooter />
    </>);
}

export default NetworkSupport;
