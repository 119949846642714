/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
const date = new Date().getFullYear();

function VeroFooter() {
  return (
    <>
    <MKBox px={2} mx={2} textAlign="center"> 
      <MKTypography variant="button" fontWeight="regular" textAlign="center">
        All rights reserved. Copyright &copy; {date} Vero Software, LLC.
        <br />
        {" "}
      </MKTypography>
      <br />
    </MKBox>
    </>
    );
  }

export default VeroFooter;
