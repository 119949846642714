/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import SdmLogo from "assets/images/SdmLogo.png";
import TisdelLogo from "assets/images/TisdelLogo.png";
import TrevarrowLogo from "assets/images/TrevarrowLogo.png";
import ShearLogo from "assets/images/shear_enterprises_logo.jpeg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              date=""
              name="Holly Ellis"
              review="The guys at Vero Software are great to work with. They got us through our D365 implementation and migration from Quickbooks after multiple failed attempts."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              date=""
              name="Tom Fath"
              review="Bob has been a great resource for us.  He's available whenever we need him to ensure our system is running smoothly.  I highly recommend him and his team."
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              date=""
              name="John Tisdel"
              review="We were one of Vero's first clients and we've continued to use them for 25 years.  They've guided us through multiple ERP implementations and integrations."
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
          <Grid container spacing={12} justifyContent="center" >
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={SdmLogo}
                alt="SDM Northcoast"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={TisdelLogo}
                alt="Tisdel Distributing"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={ShearLogo}
                alt="Shear Enterprises"
                width="100%"              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={TrevarrowLogo}
                alt="Trevarrow"
                width="100%"
              />
            </Grid>
          </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
