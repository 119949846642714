/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Images
import D365Logo from "assets/images/D365Logo.jpeg";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";


// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-tablet.jpeg";

function ERP() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                ERP Implementation  
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="warning"
              title="Business Problem"
              description="The client was facing challenges with their existing ERP system. 
              The system was not able to keep up with the growing business needs. 
              They were having difficulty with inventory control and factoring total cost of goods sold.
              
              "
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="lightbulb"
              title="Vero Software Solution"
              description="Vero Software implemented Microsoft Dynamics 365 Business Central.
              We facilitated the system implementation, configuration and process improvement, data migration and user training.
              Several customizations were made to the system to meet the client's specific needs.
              We still provide ongoing support and maintenance for the system.
              "
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="favorites"
              title="Business Results"
              description="The solution provided the client with a comprehensive view of their business operations.
              The client was able to manage their financials, automate and secure their supply chain, sell smarter and improve customer service.
              They integrated their large accounts with the new system through multiple channels (EDI, API&apos;s, and Custom Logic)."
            />
            <br/>
            <br/>
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
          <Grid container spacing={12} justifyContent="center" >
            <Grid item xs={12} md={8} lg={3}>
              <MKBox
                component="img"
                src={D365Logo}
                alt="Microsoft"
                width="100%"
              />
            </Grid>
          </Grid>
      </Card>
      <VeroFooter />
    </>
  );
}

export default ERP;
