/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/bg-tablet.jpeg";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Markdown from 'react-markdown';
import process from 'process';

export default function PostDetail() {

  const [post, setPost] = useState([]);     

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get('postId');
  console.log(token);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE}/post?postId=` + token)
      .then(res => {
        const post = res.data;
        setPost(post);
      }).catch((error) => { console.log(error.message); });
    }, []);

    return (
    <>
      <DefaultNavbar routes={routes} />  
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >      
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Vero Blog
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={12}>
                <MKTypography variant="h3" my={1}>
                    {post.title} 
                </MKTypography>
                <MKTypography variant="h6" my={1}>
                    {post.date} 
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                    <Markdown>{post.contentMarkdown}</Markdown>
                </MKTypography>                
            </Grid>
        </Grid>
      </Card>
      <VeroFooter />
      </>
  );
}

