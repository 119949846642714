/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Images
import MsftLogo from "assets/images/microsoftlogo.png";
import EpicorLogo from "assets/images/epicorlogo.png";
import SysProLogo from "assets/images/sysprologo.png";
import FreshBooksLogo from "assets/images/freshbookslogo.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg3.jpg";

function ERP() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                ERP Implementations
              </MKTypography>
              <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                With dozens of implementations, our experience and process can accelerate your implementation.
                We stress configuration and process improvement over customization.  
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="inventory"
              title="Package Selection"
              description=""
            />
            <MKTypography variant="body2" color="secondary" fontWeight="regular">


Finding the one application that best fits your organizational is a repeatable process that we’ve executed many times with great success.  
Most of the selected applications have been running for 10 to 15 years. Not a single package selected has failed to meet the busines needs.
<br/>
<br/>
The process is simple and the value is significant:
<br/>
<br/>
Gather a list of requirements for the application but focus on the uniqueness of your organization&apos;s uniqueness.  
For example, everyone needs an ERP to have invoicing capabilities but if you have a complex pricing matrix, you&apos;ll need a solution that can support your model.
A long list of potential vendors is created using the Internet, industry analysts, and past experiences.
Looking at price, complexity and services capability, it&apos;s usually a quick process to shorten the list to a few key prospects.
The vendors complete a survey and perform a scripted demo to showcase their software.
Our past experience in negotiation can reduce the price significantly just on the initial purchase.
<br/>
<br/>
Once the process is complete, we often help clients through the implementation process which reduces the overall cost.

            <br/>
            <br/>
            </MKTypography> 
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="download"
              title="Implementation"
              description=""
            />
            <MKTypography variant="body2" color="secondary" fontWeight="regular">
Once the package is selected, the implementation process begins.  We have a repeatable process that has been successful in dozens of implementations.  
We stress configuration and process improvement over customization.  This reduces the cost of the implementation and the ongoing cost of the application.
<br/>
<br/>
<il>
<li>
<b>System Installation:</b> Address the technical specifications for the application but focus on the uniqueness of your organization.  
</li>
<li>
<b>Configuration and Process Improvement:</b> We&apos;ll configure the infrastructure to meet the package requirements and then install the software and complete the initial configuration.
</li>
<li>
<b>Data Migration:</b> Working together we&apos;ll define the the migration strategy including what data to migrate, how to migrate it, and how to validate the data. 
The business processes are reviewed and adjusted to balance the needs of the business with the capabilities of the software.
</li>
<li>
<b>User Training:</b> Users will be involved in the process from the beginning. After initial user-training, we setup a conference room pilot to test the system and prepare for the go-live.
</li>
</il>
            <br/>
            <br/>
            </MKTypography> 
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
          <Grid container spacing={12} justifyContent="center" >
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={MsftLogo}
                alt="Microsoft"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={EpicorLogo}
                alt="Epicor"
                width="100%"
              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={SysProLogo}
                alt="SysPro"
                width="100%"              />
            </Grid>
            <Grid item xs={6} md={4} lg={2}>
              <MKBox
                component="img"
                src={FreshBooksLogo}
                alt="Freshbooks"
                width="100%"
              />
            </Grid>
          </Grid>
      </Card>
      <VeroFooter />
    </>
  );
}

export default ERP;
