import React from "react";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";

BlogPostSummary.propTypes = {
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    contentMarkdown: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,  
};

export default function BlogPostSummary(props) {

    return (
        <>
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={12}>
                <MKTypography variant="h3" my={1}>
                    {props.title} - {props.date}
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                    {props.summary}
                </MKTypography>
                <MKTypography
                    component="a"
                    href={"./postdetail?postId=" + props.id}
                    variant="body2"
                    color="info"
                    fontWeight="regular"
                    sx={{
                        width: "max-content",
                        display: "flex",
                        alignItems: "center", 

                        "& .material-icons-round": {
                            fontSize: "1.125rem",
                            transform: "translateX(3px)",
                            transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                        },

                        "&:hover .material-icons-round, &:focus .material-icons-round": {
                            transform: "translateX(6px)",
                        },
                    }}
                >
                    More about that
                    <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography>
            </Grid>
        </Grid><Divider sx={{ my: 6 }} />
        </>
    );
}
        

