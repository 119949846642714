/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Images
import EpicorLogo from "assets/images/epicorlogo.png";
import AzureLogo from "assets/images/azurelogo.png";


// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";


// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-designer.jpeg";

function Integration() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                System Integration  
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="warning"
              title="Business Problem"
              description="A wholesale distribution company uses Epicor Prophet 21 as their ERP system. They process hundreds of sales orders per day.
              Their main supplier changed to a managed inventory/shipment model, which required the company to send and receive data from their supplier's system. 
              The data exchange included sales orders, payables, and inventory levels.  They needed an automated process to integrate the data into Prophet 21."
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="lightbulb"
              title="Vero Software Solution"
              description="We developed a custom integration solution using Microsoft .Net running on Azure accessing Azure Storage Accounts and SQL Server.
                  The data is exchanged using FTP and parsed into a No SQL database.  The data is then integrated into Prophet 21 using RESTful APIs and JSON.  
                  The solution is scalable and can be used for other data exchange needs.  The solution is also monitored and maintained by Vero Software."
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="favorites"
              title="Business Results"
              description="The process is fully automated and has reduced the time to process the data by 90%.  
              The customer service team only needs to manage exceptions.  We've now replicated the solution for a peer organization 
              to our client and they share the cost of enhancements further reducing the cost of ownership."
            />
            <br/>
            <br/>
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
          <Grid container spacing={12} justifyContent="center" >
            <Grid item xs={12} md={8} lg={3}>
              <MKBox
                component="img"
                src={EpicorLogo}
                alt="Prophet 21"
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={8} lg={3}>
              <MKBox
                component="img"
                src={AzureLogo}
                alt="Microsoft"
                width="100%"
              />
            </Grid>
          </Grid>      </Card>
      <VeroFooter />
    </>
  );
}

export default Integration;
