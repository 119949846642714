/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Routes
import routes from "routes";

// Image
import bgImage from "assets/images/bg-tablet.jpeg";
import BobImage from "assets/images/bob-LeRoy .jpeg";
import LynnImage from "assets/images/lynn-leroy.jpeg";

function History() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="25vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
        <Grid
          item
          xs={12}
          lg={6}
          xl={10}
          mx={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            //borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            xs={12}
            sm={10}
            md={7}
            lg={6}
            xl={10} 
            mx={{ xs: "auto", lg: 6 }}
            // mt={{ xs: 20, sm: 18, md: 5 }}
            // mb={{ xs: 20, sm: 18, md: 5 }}
            // mx={{ xs: "auto" }}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                History
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKTypography variant="body2" color="text" mb={3}>
                We started our journey way back in 1998.  It was on a family vacation that we decided it was time to try this.
                The first question is &quot;what should we call it?&quot;.  Since we were on the beach in Vero, Florida, it made sense
                that we use that name.  So, Vero Software was born.  We started with a simple website and a few clients.
                <br />
                <br />
                Our first client came from a neighbor who was an operations manager.  We then partnered with an accounting firm for a while.
                Vero was put on the back burner for a while as we both had full time jobs and kids to raise.  We always supported a couple small clients in 
                our spare time.  
                <br />
                <br />
                It was during the pandemic that we decided to give it a go again.  We started with a few clients and have grown to over 20 clients.
                We are now a full service IT company.  We offer everything from website design to full network support.  
                We are a Microsoft Partner and a reseller for many other software companies.
                <br />
                <br />
                Vero is a small company of two principles; but, we have a large network of experts who we draw upon to meet our
                client needs as they arise.
                <br />
                <br />
              </MKTypography>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                  <HorizontalTeamCard
                      image={BobImage}
                      name="Bob LeRoy"
                      position={{ color: "info", label: "Chief Engineering Officer" }}
                      description="Bob's does the Software Development, Cloud, Data Analytics, and AI/ML work."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <HorizontalTeamCard
                      image={LynnImage}
                      name="Lynn LeRoy"
                      position={{ color: "info", label: "Chief Client Officer" }}
                      description="Lynn makes sure the clients are happy, the bills are paid, and Vero grows."
                    />
                  </Grid>
              </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
        </Grid>
      </Card>
      <VeroFooter />
      </>
  );
}

export default History;
