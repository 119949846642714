/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import AppDevServices from "pages/Presentation/sections/AppDevServices";
// import Environments from "pages/Presentation/sections/Environments";
// import Agile from "pages/Presentation/sections/Agile";

// About Us page sections
// import Information from "pages/LandingPages/AboutUs/sections/Information";
// import Team from "pages/LandingPages/AboutUs/sections/Team";
// import Featuring from "pages/LandingPages/AboutUs/sections/Featuring";
// import Newsletter from "pages/LandingPages/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/city-profile.jpg";

function ERP() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                IT Strategy
              </MKTypography>
              <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                Long term planning for Cloud and AI strategy for business growth  
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="inventory"
              title="IT Strategy"
              description=""
            />
            <MKTypography variant="body2" color="secondary" fontWeight="regular">

The is much to be learned from 30 years of IT consulting.  We share this knowledge through IT coaching to help companies improve the way they deliver software applications.  
The topics covered could include:
<br/>
<br/>
<il>
<li>
Mentor your internal IT manager on current trends and topics
</li>
<li>
Application portfolio analysis and rationalization
</li>
<li>
SDLC Process Improvement
</li>
<li>
Establish a Center of Excellence around a specific technology or standard (such as Cloud or Microservices)
</li>
</il>
<br/>
Some ways we do this include:
<br/>
<br/>
<il>
<li>
Facilitated sessions to understand the existing environment and create roadmaps for how to implement change.
</li>
<li>
Consensus building among your team so everyone agrees on the priorities and how to achieve them.  Most importantly in this is documentation of decision criteria for future reference.</li>
<li>
Recommendation for cost reduction and/or application consolidation.
</li>
<li>
Identification of opportunities to monetize existing intellectual property and assets.
</li>
</il>
            <br/>
            <br/>
            </MKTypography> 
          </Grid>
        </Grid>
      </Card>
      <VeroFooter />
    </>
  );
}

export default ERP;
