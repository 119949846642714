/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import CustomSoftware from "pages/Services/CustomSoftware";
import ERP from "pages/Services/ERP";
import NetworkSupport from "pages/Services/NetworkSupport";
import Strategy from "pages/Services/Strategy";
import Data from "pages/Clients/Data";
import Erp from "pages/Clients/Erp";
import Integration from "pages/Clients/Integration";
import Network from "pages/Clients/Network";
import ContactUs from "pages/ContactUs";
import History from "pages/History";
import Blog from "pages/Blog";


// Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";

const routes = [
  {
    name: "Our Services",
    icon: <Icon>view_day</Icon>,
    collapse: [
      {
        name: "Custom Software",
        description: "We build custom software for your business need",
        route: "/pages/services/custom-software",
        component: <CustomSoftware />,
      },
      {
        name: "Network Support",
        description: "Network monitoring and desktop support",
        route: "/pages/services/network-support",
        component: <NetworkSupport />,
      },
      {
        name: "ERP Implementations",
        description: "ERP selection, implementation, and configuration",
        route: "/pages/services/erp-implementations",
        component: <ERP />,
      },
      {
        name: "IT strategy",
        description: "Long-term planning for cloud and AI strategy for business growth",
        route: "/pages/services/it-strategy",
        component: <Strategy />,
      },
    ],
  },
  {
    name: "client Stories",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Data & AI",
        description: "Data Analytics Product Development",
        route: "/pages/clients/data",
        component: <Data />,
      },
      {
        name: "ERP Implementation",
        description: "Wholesale Distribution ERP Implementation",
        route: "/pages/clients/Erp",
        component: <Erp />,
      },
      {
        name: "System Integration",
        description: "ERP Custommization and Integration",
        route: "/pages/clients/Integration",
        component: <Integration />,
      },
      {
        name: "Network Support",
        description: "Remote Network Support and Monitoring",
        route: "/pages/clients/Network",
        component: <Network />,
      },
    ],
  },
  {
    name: "About Us",
    icon: <Icon>badge</Icon>,
    collapse: [
      {
        name: "Blog",
        route: "/pages/blog",
        component: <Blog />,
      },
      {
      name: "Contact",
      route: "/pages/contacts",
      component: <ContactUs />,
      },
      {
        name: "History",
        route: "/pages/history",
        component: <History />,
      },
    ],
  }
];

export default routes;
