/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import VeroFooter from "components/VeroFooter";

// Images
import CiscoLogo from "assets/images/ciscologo.png";
import KaseyaLogo from "assets/images/kaseyalogo.png";
import Sental1Logo from "assets/images/sentinalonelogo.png";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";


// Routes
import routes from "routes";

// Images
import bgImage from "assets/images/bg-wave.jpeg";

function Network() {
  return (
    <>
      <DefaultNavbar routes={routes} />
      <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Network Support                  
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >        
        <Grid container spacing={1} xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="warning"
              title="Business Problem"
              description="This client had a engaged with a small IT company to provide network support. 
              When client experienced issues with their network, each call for support introduced a different technician who had to learn the 
              environment again. The client was looking for a new IT company to provide network support and a dedicated team who know the environment."
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="lightbulb"
              title="Vero Software Solution"
              description="We did an assessment of the client's network and provided a proposal for network monitoring and support.
                We also offered to transfer their licensing to better manage the monthly expenses.
                Implemented quarterly business reviews to ensure the client's network is aligned with their business goals.
              "
            />
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <DefaultInfoCard
              icon="favorites"
              title="Business Results"
              description="The client has been with Vero for over 5 years and has been very satisfied with the service.
                        When they call for support they know who they are going to get and they know their environment.
                        We reduced their monthly license expenses by 30% and have provided a stable network environment."
            />
            <br/>
            <br/>
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
          <Grid container spacing={12} justifyContent="center" >
            <Grid item xs={12} md={8} lg={2}>
              <MKBox
                component="img"
                src={CiscoLogo}
                alt="Cisco"
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={8} lg={2}>
              <MKBox
                component="img"
                src={Sental1Logo}
                alt="SentinalOne"
                width="100%"
              />
            </Grid>
            <Grid item xs={12} md={8} lg={2}>
              <MKBox
                component="img"
                src={KaseyaLogo}
                alt="Kaseya"
                width="100%"
              />
            </Grid>

          </Grid>
      </Card>
      <VeroFooter />
    </>
  );
}

export default Network;
